































































































































































































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { extend } from "vee-validate";

extend("splitTotal", {
  params: ["collab", "third"],
  // @ts-ignore
  validate(value, { collab, third }) {
    if (value + collab + third == 0) {
      return true;
    }
    return value + collab + third == 100;
  },
  message: "Creator, Collab, and third party splits must add up to 100%",
});

@Component
export default class PaymentEdit extends Vue {
  @Prop() public readonly createView!: boolean;
  @Prop() public readonly readonly!: boolean;

  @PropSync("payment") public paymentForm!: any;
  @PropSync("splits") public splitsForm!: any;

  public get creatorSplit() {
    return this.splitsForm.creator * 100;
  }

  public set creatorSplit(newValue) {
    this.splitsForm.creator = newValue / 100;
  }

  public get collabSplit() {
    return this.splitsForm.collab * 100;
  }

  public set collabSplit(newValue) {
    this.splitsForm.collab = newValue / 100;
  }

  public get thirdPartySplit() {
    return this.splitsForm.third_party * 100;
  }

  public set thirdPartySplit(newValue) {
    this.splitsForm.third_party = newValue / 100;
  }

  public mounted() {
    if (!this.createView) {
      const accountId = parseInt(this.$route.params.id, 0);
      window["analytics"]["page"](`/mastersheet/${accountId}/payment`);
    }
  }
}
