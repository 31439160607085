import { render, staticRenderFns } from "./PodcastEdit.vue?vue&type=template&id=2f016816&scoped=true&"
import script from "./PodcastEdit.vue?vue&type=script&lang=ts&"
export * from "./PodcastEdit.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f016816",
  null
  
)

export default component.exports