







































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { AccountDraft } from "@/interfaces";
import "@/assets/styles/account-dialog.css";

@Component
export default class MastersheetDraft extends Vue {
  @Prop()
  public drafts: Array<AccountDraft>;

  @PropSync("isOpen", { type: Boolean })
  public isOpenSync!: boolean;

  public hide() {
    this.isOpenSync = false;
  }

  public formateDate(datetime) {
    return datetime.substring(0, 16).replace("T", " ");
  }

  public getExpiringInDays(lastUpdated) {
    const timeDiff = new Date().getTime() - new Date(lastUpdated).getTime();
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return 7 - daysDiff;
  }

  public addNewAccount() {
    this.$emit("add-new");
  }

  public editDraft(id: number) {
    this.$emit("edit-draft", id);
  }
}
