




















































































































































































































import { debounce } from "lodash";
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import {
  readAccountTeams,
  readClasses,
  readContractStatuses,
  readOffices,
  readRights,
  readTiers,
} from "@/store/main/getters";
import { api } from "@/api";
import { DashboardUser } from "@/interfaces";

@Component
export default class AccountEdit extends Vue {
  @Prop() public readonly createView!: boolean;
  @Prop() public readonly readonly!: boolean;

  @PropSync("account") public accountForm!: any;

  public platforms = [
    "YouTube",
    "Instagram",
    "Facebook",
    "TikTok",
    "Twitter",
    "Twitch",
    "LikeApp",
    "Douyin",
    "CHZZK",
  ];

  public chinaPlatforms = [
    "Bilibili",
    "Bilibili Premiere",
    "Xigua",
    "Weibo",
    "Red",
    "iQIYI",
  ];

  public isDashboardUserLoading = false;
  public dashboardUser: DashboardUser | null = null;

  public get offices() {
    return readOffices(this.$store);
  }

  public get accountTeams() {
    return readAccountTeams(this.$store);
  }

  public get contractStatuses() {
    return readContractStatuses(this.$store);
  }

  public get tiers() {
    return readTiers(this.$store);
  }

  public get rights() {
    return readRights(this.$store);
  }

  public get classes() {
    return readClasses(this.$store);
  }

  public get isChinaPlatform() {
    return this.accountForm.type === "China Platform";
  }

  public get allPlatforms() {
    if (this.isChinaPlatform) {
      return [...this.platforms, ...this.chinaPlatforms];
    }
    return this.platforms;
  }

  public get expirationRequirement() {
    if ([4, 5].includes(this.accountForm.right_id)) {
      return "required";
    }
    return "";
  }

  @Watch("accountForm.right_id", { immediate: true })
  public changeAccountType(input: number, original: number) {
    let accountType: string;
    let right;
    if (input) {
      right = this.rights.filter((item) => item.id === input)[0];
      if (right) {
        if (["Network", "Music (Channel + Asset)"].includes(right.name)) {
          accountType = "YouTube Channel";
        } else if (right.name === "Podcast") {
          accountType = "Podcast";
        } else if (right.name === "China Platform") {
          accountType = "China Platform";
        } else {
          accountType = "Clip Licensing";
        }
      }
      if (!this.createView && this.accountForm.type !== accountType) {
        this.accountForm.right_id = original;
        this.$toast.add({
          severity: "warn",
          group: "bl",
          summary: "Incompatible Account Right",
          detail: `This account is a ${this.accountForm.type}, which is incompatible with the "${right.name}" Right.\nPlease create a new account to switch to that Right.`,
          life: 10000,
        });
        return;
      }
    } else {
      accountType = "YouTube Channel";
    }
    this.accountForm.right_id = input;
    this.accountForm.type = accountType;
  }

  public async fetchDashboardUser() {
    if (
      !this.createView ||
      !this.accountForm.dashboard_id ||
      this.dashboardUser?.id === parseInt(this.accountForm.dashboard_id)
    ) {
      return;
    }

    this.isDashboardUserLoading = true;
    this.$emit("dashboard-user-updating");
    const response = await api.getDashboardUser(this.accountForm.dashboard_id);
    if (response && response.data) {
      this.dashboardUser = response.data;
      const { email, youtube_channels } = this.dashboardUser;
      this.$set(this.accountForm, "contact_email", email);
      this.$emit("dashboard-user-updated", this.dashboardUser);
    }
    this.isDashboardUserLoading = false;
  }

  public mounted() {
    if (!this.createView) {
      const accountId = parseInt(this.$route.params.id, 0);
      window["analytics"]["page"](`/mastersheet/${accountId}/account`);
    }
  }
}
