



















































































































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { readSearchCreatorFuse } from "@/store/account/getters";
import { api } from "@/api";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";

@Component({
  components: {
    Autocomplete,
  },
})
export default class CreatorEdit extends Vue {
  @Prop() public readonly createView!: boolean;
  @Prop() public readonly readonly!: boolean;

  @PropSync("creatorId") public syncedCreatorId!: any;

  public creator: any = {};

  public get searchCreatorFuse() {
    return readSearchCreatorFuse(this.$store);
  }

  public searchInput = {
    id: null,
    full_name: "",
  };
  public searchSuggestions = [];
  public displaySearch = false;

  @Watch("syncedCreatorId", { immediate: true })
  public async fetchCreator() {
    if (this.syncedCreatorId) {
      const response = await api.getCreator(this.syncedCreatorId);
      if (response) {
        this.creator = response.data;
      }
    } else {
      this.creator = {};
    }
  }

  public async mounted() {
    if (!this.createView) {
      const accountId = parseInt(this.$route.params.id, 0);
      window["analytics"]["page"](`/mastersheet/${accountId}/creator`);
    }
    await this.fetchCreator();
  }

  public searchCreator(event) {
    return this.searchCreatorFuse.search(event).map((item) => item.item);
  }

  public async changeCreator(event) {
    if (event) {
      this.syncedCreatorId = event.id;
    } else {
      this.syncedCreatorId = null;
    }
    this.displaySearch = false;
    this.$refs["autocomplete-search"]["value"] = undefined;
  }
}
