







import { Component, Vue } from "vue-property-decorator";

@Component
export default class MastersheetManagers extends Vue {
  get managers() {
    const role = this.$props.params.colDef.headerName;
    let managers;
    if (this.$props.params.value) {
      switch (role) {
        case "General Managers":
          managers = this.$props.params.value.filter((item) => true);
          break;
        case "Contact Managers":
          managers = this.$props.params.value.filter(
            (item) => item.role === "Contact"
          );
          break;
        case "Optimize Managers":
          managers = this.$props.params.value.filter(
            (item) => item.role === "Optimize"
          );
          break;
        case "Claim Managers":
          managers = this.$props.params.value.filter(
            (item) => item.role === "Claim"
          );
          break;
      }
    } else {
      managers = null;
    }
    if (managers) {
      return managers.map((item) => item.first_name).join(", ");
    }
    return "";
  }
}
