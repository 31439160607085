





























import { Component, Vue } from "vue-property-decorator";

@Component
export default class EditIcon extends Vue {
  public invokeParentMethod() {
    this.$router.push(`/mastersheet/${this["params"]["value"]}`);
  }
}
