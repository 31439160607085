
































































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import {
  readLanguages,
  readPodcastGenres,
  readPodcastStatuses,
} from "@/store/main/getters";
import Password from "primevue/password";

@Component({
  components: {
    Password,
  },
})
export default class PodcastEdit extends Vue {
  @Prop() public readonly createView!: boolean;
  @Prop() public readonly readonly!: boolean;

  @PropSync("podcast") podcastForm!: any;

  public mainGenre: string = "";

  public get languages() {
    return readLanguages(this.$store);
  }

  public get podcastStatuses() {
    return readPodcastStatuses(this.$store);
  }

  public get podcastGenres() {
    return readPodcastGenres(this.$store);
  }

  public get mainGenres() {
    return [...new Set(this.podcastGenres.map((item) => item.name))];
  }

  public get subGenres() {
    return this.podcastGenres.filter((item) => item.name === this.mainGenre);
  }

  private updateMainGenre() {
    this.mainGenre = this.podcastGenres.find(
      (item) => item.id === this.podcastForm.podcast_genre_id
    )?.name;
  }

  @Watch("podcastGenres")
  public updateGenre() {
    if (!this.createView && this.mainGenre === "") {
      this.updateMainGenre();
    }
  }

  public mounted() {
    if (!this.createView) {
      const accountId = parseInt(this.$route.params.id, 0);
      window["analytics"]["page"](`/mastersheet/${accountId}/podcast`);
      if (this.podcastGenres.length > 0) {
        this.updateMainGenre();
      }
    }
  }
}
