import { api } from "@/api";

export default async (byPassCache = false) => {
  const response = await api.listAccountsFull(byPassCache);
  return response.data.map((account) => {
    // @ts-ignore
    account.managers = JSON.parse(account.managers);
    // @ts-ignore
    account.payment_info = JSON.parse(account.payment_info);
    // @ts-ignore
    account.social_media = JSON.parse(account.social_media);
    // @ts-ignore
    account.splits = JSON.parse(account.splits);
    account.clip_license__documentation = JSON.parse(
      account.clip_license__documentation
    );
    return account;
  });
};
