
























































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { readNetworks } from "@/store/main/getters";

@Component
export default class ClipEdit extends Vue {
  @Prop() public readonly createView!: boolean;
  @Prop() public readonly readonly!: boolean;

  @PropSync("clip") public clipForm!: any;

  public get networks() {
    return readNetworks(this.$store);
  }

  public documentations = [
    "ID Card",
    "Video Call screenshot",
    "Screen record",
    "NPWP for Indonesia",
    "Contract",
  ];

  public mounted() {
    if (!this.createView) {
      const accountId = parseInt(this.$route.params.id, 0);
      window["analytics"]["page"](`/mastersheet/${accountId}/clip`);
    }
  }
}
