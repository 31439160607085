










































































import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import {
  readCategories,
  readContentFormats,
  readContentSources,
  readNetworks,
  readNetworkStatuses,
} from "@/store/main/getters";
import { dispatchListCategories } from "@/store/main/actions";

@Component
export default class CategoryEdit extends Vue {
  @Prop() public readonly createView!: boolean;
  @Prop() public readonly readonly!: boolean;

  @PropSync("network") public networkForm!: any;

  public main_category_1: string = "";

  public async mounted() {
    if (!this.createView) {
      const accountId = parseInt(this.$route.params.id, 0);
      window["analytics"]["page"](`/mastersheet/${accountId}/category`);
    }
    if (!this.categories.length) {
      await dispatchListCategories(this.$store);
    }
    this.getCurrentCategories();
  }

  @Watch("networkForm", { immediate: false, deep: true })
  public getCurrentCategories() {
    const category_1_id = this.networkForm.category_1_id;
    const category_1: any =
      this.categories.filter((item) => item.id === category_1_id)[0] || {};
    this.main_category_1 = category_1.main_category;
  }

  public get networks() {
    return readNetworks(this.$store);
  }

  public get contentFormats() {
    return readContentFormats(this.$store);
  }

  public get contentSources() {
    return readContentSources(this.$store);
  }

  public get networkStatuses() {
    return readNetworkStatuses(this.$store);
  }

  public get categories() {
    return readCategories(this.$store);
  }

  public get main_categories() {
    return [...new Set(this.categories.map((item) => item.main_category))];
  }

  public sub_categories(main_category) {
    return this.categories.filter(
      (item) => item.main_category === main_category
    );
  }
}
