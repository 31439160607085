




















































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import FileUpload from "primevue/fileupload";
import { accountFilesApi } from "@/apis/account-files";
import { AccountFile } from "@/interfaces";
import "@/assets/styles/file-upload.css";

@Component({
  components: {
    FileUpload,
  },
})
export default class FilesEdit extends Vue {
  @Prop() public readonly readonly: boolean;

  public accountId: number | undefined;
  public files: Array<AccountFile & { icon: string }> = [];
  public isListLoading: boolean = false;
  public isDeleting: boolean = false;
  public isSaving: boolean = false;
  public showForm: boolean = false;
  public showFileError: boolean = false;
  public fileTypes = [
    {
      label: "Link",
      value: "link",
    },
    {
      label: "Contract Upload",
      value: "file",
    },
  ];
  public form = {
    id: undefined,
    type: "link",
    url: "",
    description: "",
  };
  public file: File | undefined;

  public get disableActionButtons() {
    return this.isDeleting || this.isSaving;
  }

  public get isLink() {
    return this.form.type === "link";
  }

  public get isEdit() {
    return this.form.id !== undefined;
  }

  public displayForm(file: AccountFile = undefined) {
    this.showForm = true;

    if (file) {
      this.form = {
        id: file.id,
        type: file.file_key ? "file" : "link",
        url: file.url,
        description: file.description,
      };
    }
  }

  public hideForm() {
    this.showForm = false;
    this.form = {
      id: undefined,
      type: "link",
      url: "",
      description: "",
    };
  }

  public openFileBrowser(event) {
    event.target
      .closest(".file-upload")
      .querySelector("input[type='file']")
      .click();
  }

  public updateFile(event) {
    this.file = event.files[0];
    if (this.file !== undefined) {
      this.showFileError = false;
    }
  }

  public clearFile() {
    this.file = undefined;
  }

  public async deleteFile(fileId: number) {
    this["$dialog"]
      .confirm(
        "Are you sure you want to delete this file",
        this["$deleteOptions"]
      )
      .then(async () => {
        this.isDeleting = true;
        const response = await accountFilesApi.deleteFile(
          this.accountId,
          fileId
        );
        if (response && response.status === 200) {
          const fileIndex = this.files.findIndex((file) => file.id === fileId);
          this.files.splice(fileIndex, 1);
          this.$toast.add({
            severity: "success",
            detail: "File successfully deleted.",
            life: 3000,
          });
        } else {
          this.$toast.add({
            severity: "error",
            detail: "Failed to delete file.",
            life: 3000,
          });
        }
        this.isDeleting = false;
      });
  }

  public async open(fileId: number) {
    const response = await accountFilesApi.getFile(this.accountId, fileId);
    if (response && response.status === 200) {
      const { file_key, url } = response.data;
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      this.$toast.add({
        severity: "error",
        detail: "Failed to get file URL.",
        life: 3000,
      });
    }
  }

  public async save(event) {
    await this.$refs["file-form-validation-observer"]["validate"]();
    if (!this.$refs["file-form-validation-observer"]["flags"].valid) {
      if (!this.isLink && !this.file) {
        this.showFileError = true;
      }
      return;
    }

    this.isSaving = true;

    let response;
    if (this.isEdit) {
      response = await accountFilesApi.updateFile(
        this.accountId,
        this.form.id,
        this.form.description.trim(),
        this.isLink ? this.form.url.trim() : undefined
      );
    } else if (this.isLink) {
      response = await accountFilesApi.addFileLink(
        this.accountId,
        this.form.description.trim(),
        this.form.url.trim()
      );
    } else {
      response = await accountFilesApi.uploadFile(
        this.accountId,
        this.form.description.trim(),
        this.file
      );
    }
    if (response && response.status === 200) {
      this.getFileList();
      this.showForm = false;
      this.form = {
        id: undefined,
        type: "link",
        url: "",
        description: "",
      };
      this.$toast.add({
        severity: "success",
        detail: "File successfully saved.",
        life: 3000,
      });
    } else {
      this.$toast.add({
        severity: "error",
        detail: "Failed to save file.",
        life: 3000,
      });
    }
    this.isSaving = false;
  }

  public getIcon(key: string | null) {
    if (!key) {
      return "pi-link";
    }
    if (key.includes("pdf")) {
      return "pi-file-pdf";
    }

    return key.includes("doc") ? "pi-file-word" : "pi-image";
  }

  public getFileList() {
    this.isListLoading = true;
    accountFilesApi.listFiles(this.accountId).then((response) => {
      if (response && response.status === 200) {
        this.files = response.data.map((file) => {
          return {
            ...file,
            icon: this.getIcon(file.file_key),
          };
        });
      } else {
        this.$toast.add({
          severity: "error",
          detail: "Failed to retrieve files for this account.",
          life: 3000,
        });
      }
      this.isListLoading = false;
    });
  }

  public addEventListeners() {
    const dropzone = document.getElementsByClassName("p-fileupload-content")[0];
    dropzone.addEventListener("dragover", (event) => {
      dropzone.classList.add("dragging");
    });
    dropzone.addEventListener("dragleave", (event) => {
      dropzone.classList.remove("dragging");
    });
    dropzone.addEventListener("drop", (event) => {
      dropzone.classList.remove("dragging");
    });
  }

  public created() {
    this.accountId = parseInt(this.$route.params.id, 0);
    this.getFileList();
  }
}
