



























































































































































import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import AutoComplete from "primevue/autocomplete";
import InlineMessage from "primevue/inlinemessage";
import {
  readCategories,
  readContentFormats,
  readContentSources,
  readNetworks,
  readNetworkStatuses,
} from "@/store/main/getters";
import { api } from "@/api";
import { DashboardYouTubeChannel } from "@/interfaces";

@Component({
  components: {
    AutoComplete,
    InlineMessage,
  },
})
export default class YoutubeEdit extends Vue {
  @Prop() public readonly createView!: boolean;
  @Prop() public readonly readonly!: boolean;
  @Prop() public readonly isChannelListLoading!: boolean;
  @Prop() public readonly linkedChannels!: DashboardYouTubeChannel[];
  @Prop() public readonly thumbnailUrl: string | null;

  @PropSync("youtube") youtubeForm!: any;

  public main_category_1: string = "";
  public isChannelDetailsLoading: boolean = false;
  public selectedChannel: any = null;
  public filteredChannels = [];
  public isDuplicate = false;

  public get networks() {
    return readNetworks(this.$store);
  }

  public get contentFormats() {
    return readContentFormats(this.$store);
  }

  public get contentSources() {
    return readContentSources(this.$store);
  }

  public get networkStatuses() {
    return readNetworkStatuses(this.$store);
  }

  public get categories() {
    return readCategories(this.$store);
  }

  public get main_categories() {
    return [...new Set(this.categories.map((item) => item.main_category))];
  }

  public get unlinkedDateFieldRules() {
    return [2, 6].includes(this.youtubeForm.network_status_id)
      ? "required"
      : "";
  }

  public sub_categories(main_category) {
    return this.categories.filter(
      (item) => item.main_category === main_category
    );
  }

  public searchChannels(event) {
    this.filteredChannels = this.linkedChannels.filter((channel) => {
      return channel.youtube_channel_id.includes(event.query);
    });
  }

  @Watch("selectedChannel")
  public updateYouTubeFormId() {
    if (this.selectedChannel.youtube_channel_id) {
      this.$set(
        this.youtubeForm,
        "id",
        this.selectedChannel.youtube_channel_id
      );
    } else {
      this.$set(this.youtubeForm, "id", this.selectedChannel);
    }
  }

  @Watch("youtubeForm.id")
  public async watchYouTubeId() {
    this.isDuplicate = false;
    if (!this.createView || !this.youtubeForm.id.match(/^UC.{22}$/)) {
      return;
    }

    this.isChannelDetailsLoading = true;
    const response = await api.getYoutubeChannelDetails(this.youtubeForm.id);
    if (response && response.data) {
      const {
        title,
        contentOwnerId,
        linkTime,
        subscriberCount,
        totalVideoViewCount,
        exists,
      } = response.data;
      this.$set(this.youtubeForm, "channel_name", title);
      this.$set(this.youtubeForm, "subscribers", subscriberCount);
      this.$set(this.youtubeForm, "views", totalVideoViewCount);
      if (contentOwnerId) {
        const network = this.networks.find(
          (network) => network.id === contentOwnerId
        );
        if (network) {
          this.$set(this.youtubeForm, "network_id", network.id);
          this.$set(this.youtubeForm, "linked_date", linkTime);
          this.$set(this.youtubeForm, "network_status_id", 1);
        }
      }
      this.isDuplicate = exists;
    }
    this.isChannelDetailsLoading = false;
  }

  public mounted() {
    if (!this.createView) {
      const accountId = parseInt(this.$route.params.id, 0);
      window["analytics"]["page"](`/mastersheet/${accountId}/youtube`);
    }
  }
}
