var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-input-container",attrs:{"id":"manager-tab"}},[_c('ValidatedInput',{attrs:{"rules":"","label":"","name":"members","hidden":""}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.members),expression:"members"}],domProps:{"value":(_vm.members)},on:{"input":function($event){if($event.target.composing){ return; }_vm.members=$event.target.value}}})]),_c('Dialog',{staticStyle:{"padding":"1rem"},attrs:{"visible":_vm.dialog},on:{"update:visible":function($event){_vm.dialog=$event}}},[_c('DataView',{attrs:{"value":_vm.namedMappingGroups,"layout":"grid"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"p-d-flex p-jc-between p-ai-center"},[_vm._v(" Manager Group Templates ")])]},proxy:true},{key:"grid",fn:function(slotProps){return [_c('div',{staticClass:"p-col-4 p-p-3"},[_c('div',{staticClass:"grid-item-container",on:{"click":function($event){return _vm.loadMappingGroupTemplate(slotProps.data)}}},[_c('div',{staticClass:"p-mb-0 p-text-bold"},[_vm._v(_vm._s(slotProps.data.name))]),_c('div',{staticClass:"p-mb-2 p-text-light"},[_vm._v(" "+_vm._s(slotProps.data.description)+" ")]),_c('div',_vm._l((slotProps.data.members),function(member){return _c('div',{key:member.id,staticClass:"p-grid p-d-flex p-ai-center p-jc-between p-my-1"},[_c('div',{staticClass:"p-col"},[_vm._v(" "+_vm._s(member.first_name)+" "+_vm._s(member.last_name)+" ")]),_c('div',{staticClass:"p-col-fixed",staticStyle:{"width":"3rem"}},[_c('country-flag',{attrs:{"country":_vm.offices.filter(
                        function (item) { return member.office_id === item.id; }
                      )[0].code,"size":"small"}})],1),_c('div',{staticClass:"p-col"},[_vm._v(" "+_vm._s(_vm.getRoleName(member.role_id))+" ")]),_c('div',{staticClass:"p-col-fixed"},[_vm._v(" "+_vm._s(_vm._f("percentage")(member.split))+" ")])])}),0)])])]}}])})],1),_c('ValidationProvider',{ref:"managerList",attrs:{"immediate":"","rules":"managerSplitTotal:@members","vid":"managerSplitTotal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('DataView',{staticClass:"member-grid",attrs:{"value":_vm.members,"layout":"list"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"p-d-flex p-jc-between p-ai-center"},[_vm._v(" Managed by "),(!_vm.readonly)?_c('Button',{staticClass:"p-button-danger",on:{"click":_vm.resetMembers}},[_vm._v(" Reset "),_c('i',{staticClass:"pi pi-replay",staticStyle:{"margin-left":"0.75rem"}})]):_vm._e()],1)]},proxy:true},{key:"list",fn:function(slotProps){return [_c('div',{staticClass:"p-col-12"},[_c('div',{staticClass:"member-grid-item-container p-grid p-ai-center"},[_c('span',{staticClass:"p-col"},[_c('country-flag',{staticClass:"p-mr-0",attrs:{"country":_vm.offices.filter(
                    function (item) { return slotProps.data.office_id === item.id; }
                  )[0].code,"size":"small"}}),_vm._v(" "+_vm._s([slotProps.data.first_name, slotProps.data.last_name].join( " " ))+" ")],1),_c('span',{staticClass:"p-col-fixed",staticStyle:{"width":"11rem"}},[_vm._v(" "+_vm._s(slotProps.data.email)+" ")]),_c('span',{staticClass:"p-col-fixed",staticStyle:{"width":"5rem"}},[_vm._v(" "+_vm._s(_vm.teamName(slotProps.data.team_id))+" ")]),_c('span',{staticClass:"p-col"},[_c('Dropdown',{attrs:{"disabled":_vm.readonly,"options":_vm.managerRoles,"optionLabel":"name","optionValue":"id","appendTo":"body"},model:{value:(slotProps.data.role_id),callback:function ($$v) {_vm.$set(slotProps.data, "role_id", $$v)},expression:"slotProps.data.role_id"}})],1),_c('span',{staticClass:"p-col-fixed"},[_c('InputNumber',{ref:'vi-' + slotProps.data.id + '-input',staticClass:"percentage-input",attrs:{"min":0,"max":100,"mode":"decimal","minFractionDigits":1,"maxFractionDigits":2,"value":slotProps.data.split * 100,"readonly":_vm.readonly,"suffix":"%"},on:{"input":function ($event) {
                    slotProps.data.split = $event / 100;
                    _vm.$refs.managerList.validate();
                  }}})],1),(!_vm.readonly)?_c('span',{staticClass:"p-col-fixed",staticStyle:{"width":"1rem"}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:('Delete'),expression:"'Delete'",modifiers:{"bottom":true}}],staticClass:"pi pi-trash",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.removeMember(slotProps.data.id)}}})]):_vm._e()])])]}}],null,true)}),(!_vm.readonly && errors.length)?_c('div',{staticClass:"form-field-error-message p-ml-2 p-mt-2"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])}),(!_vm.readonly)?_c('div',{staticClass:"p-d-flex p-jc-between p-mt-3"},[_c('autocomplete',{ref:"autocomplete-search",attrs:{"search":_vm.searchManagers,"placeholder":"Search for a manager to add","aria-label":"Search","debounceTime":300,"autoSelect":true,"id":"autocomplete-search-managers","base-class":"autocomplete-search"},on:{"submit":_vm.selectManager},scopedSlots:_vm._u([{key:"result",fn:function(ref){
                  var result = ref.result;
                  var props = ref.props;
return [_c('li',_vm._b({staticClass:"autocomplete-result",staticStyle:{"padding-bottom":"0.5rem"}},'li',props,false),[_c('div',{staticClass:"p-d-flex p-ai-center p-jc-between"},[_c('div',{staticClass:"p-d-flex p-ai-center"},[_c('avatar',{staticClass:"p-mr-3",attrs:{"username":result.first_name,"src":result.photo_url}}),_c('span',[_vm._v(" "+_vm._s(result.first_name)+" "+_vm._s(result.last_name)+" ")])],1),_c('country-flag',{attrs:{"country":_vm.offices.filter(function (item) { return result.office_id === item.id; })[0].code,"size":"small"}})],1)])]}}],null,false,784933314)}),_c('Button',{on:{"click":function($event){_vm.dialog = true}}},[_c('i',{staticClass:"pi pi-users",staticStyle:{"font-size":"14px","margin-right":"1rem"}}),_vm._v(" Load manager group templates ")])],1):_vm._e(),(_vm.isAlpha && !_vm.createView)?_c('DataTable',{staticClass:"p-my-4",attrs:{"value":_vm.managerHistory,"loading":_vm.managerHistoryIsLoading},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._v("No records found.")]},proxy:true}],null,false,3278234287)},[_c('Column',{attrs:{"header":"Manager History"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_c('table',_vm._l((slotProps.data.managers),function(manager){return _c('tr',[_c('td',{attrs:{"width":"50%"}},[_vm._v(" "+_vm._s(((manager.manager.name) + " (" + (manager.manager.email) + ")"))+" ")]),_c('td',{attrs:{"align":"center","width":"25%"}},[_vm._v(_vm._s(manager.role))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(manager.split))])])}),0)]}}],null,false,1080889870)}),_c('Column',{attrs:{"field":"created_at","header":"Date Deleted"},scopedSlots:_vm._u([{key:"body",fn:function(slotProps){return [_vm._v(" "+_vm._s(_vm.dayjs(slotProps.data.created_at).format("YYYY-MM-DD"))+" ")]}}],null,false,1753914942)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }