import { apiUrl } from "@/env";
import axios from "axios";

export const accountFilesApi = {
  async listFiles(accountId: number) {
    const params = new URLSearchParams();
    return axios.get(`${apiUrl}/api/accounts/${accountId}/files/`);
  },

  async getFile(accountId: number, fileId: number) {
    return axios.get(`${apiUrl}/api/accounts/${accountId}/files/${fileId}/`);
  },

  async updateFile(
    accountId: number,
    fileId: number,
    description: string,
    url: string | undefined
  ) {
    const params: {
      description: string;
      url?: string;
    } = { description };
    if (url) {
      params.url = url;
    }
    return axios.patch(
      `${apiUrl}/api/accounts/${accountId}/files/${fileId}/`,
      params
    );
  },

  async deleteFile(accountId: number, fileId: number) {
    return axios.delete(`${apiUrl}/api/accounts/${accountId}/files/${fileId}/`);
  },

  async addFileLink(accountId: number, description: string, link: string) {
    const formData = new FormData();
    formData.append("description", description);
    formData.append("url", link);
    return axios.post(`${apiUrl}/api/accounts/${accountId}/files/`, formData);
  },

  async uploadFile(accountId: number, description: string, file: File) {
    const formData = new FormData();
    formData.append("description", description);
    formData.append("file", file);
    return axios.post(`${apiUrl}/api/accounts/${accountId}/files/`, formData);
  },
};
