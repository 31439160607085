import { apiUrl } from "@/env";
import axios from "axios";

export const accountDraftsApi = {
  async getDraftAccount(id: number) {
    return axios.get(`${apiUrl}/api/accounts/drafts/${id}/`);
  },

  async listDraftAccounts() {
    return axios.get(`${apiUrl}/api/accounts/drafts/`);
  },

  async createDraftAccount(createData: object) {
    return axios.post(`${apiUrl}/api/accounts/drafts/`, createData);
  },

  async updateDraftAccount(id: Number, updateData: object) {
    return axios.patch(`${apiUrl}/api/accounts/drafts/${id}/`, updateData);
  },

  async deleteDraftAccount(id: Number) {
    return axios.delete(`${apiUrl}/api/accounts/drafts/${id}/`);
  },
};
