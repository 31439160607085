







































































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { readChinaPlatformNetworkStatuses } from "@/store/main/getters";
import { api } from "@/api";

@Component
export default class YoutubeEdit extends Vue {
  @Prop() public readonly createView!: boolean;
  @Prop() public readonly readonly!: boolean;

  @PropSync("chinaPlatform") public chinaPlatformForm!: any;

  public get networkStatuses() {
    return readChinaPlatformNetworkStatuses(this.$store);
  }

  public get unlinkedDateFieldRules() {
    return [2, 6].includes(this.chinaPlatformForm.network_status_id)
      ? "required"
      : "";
  }

  public mounted() {
    if (!this.createView) {
      const accountId = parseInt(this.$route.params.id, 0);
      window["analytics"]["page"](`/mastersheet/${accountId}/china-platform`);
    }
  }
}
