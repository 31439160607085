






















































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { accountDraftsApi } from "@/apis/account-drafts";
import {
  readAccountTeams,
  readCategories,
  readClasses,
  readContentFormats,
  readContentSources,
  readContractStatuses,
  readNetworks,
  readNetworkStatuses,
  readChinaPlatformNetworkStatuses,
  readOffices,
  readRights,
  readTeams,
  readTiers,
} from "@/store/main/getters";
import { readIsManager } from "@/store/auth/getters";
import {
  dispatchListAccountTeams,
  dispatchListCategories,
  dispatchListClasses,
  dispatchListContentFormats,
  dispatchListContentSources,
  dispatchListContractStatuses,
  dispatchListNetworks,
  dispatchListNetworkStatuses,
  dispatchListChinaPlatformNetworkStatuses,
  dispatchListOffices,
  dispatchListPodcastGenres,
  dispatchListPodcastStatuses,
  dispatchListRights,
  dispatchListTeams,
  dispatchListTiers,
} from "@/store/main/actions";
import { AccountFull } from "@/interfaces";
import MastersheetEdit from "./MastersheetEdit.vue";
import MastersheetDraft from "./MastersheetDraft.vue";
import MastersheetImport from "./MastersheetImport.vue";
import OmniSearchBox from "@/components/OmniSearchBox.vue";
import { dispatchGetSearchCreatorFuse } from "@/store/account/actions";
import { AgGridVue } from "ag-grid-vue";
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import CustomLoadingMastersheet from "@/components/CustomLoadingMastersheet.vue";
import CustomNoRowsMastersheet from "@/components/CustomNoRowsMastersheet.vue";
import EditIcon from "@/components/icons/AccountEditIcon.vue";
import MastersheetLink from "@/components/MastersheetLink.vue";
import MastersheetManagers from "@/components/MastersheetManagers.vue";
import { api } from "@/api";
import CountryIcon from "@/components/CountryIcon.vue";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import UserInfo from "@/components/UserInfo.vue";
import { readIsSuper } from "@/store/auth/getters";
import dayjs from "dayjs";
import { downloadExport } from "@/utils";
import TopBar from "@/components/TopBar.vue";
import getAccountsFull from "@/helpers/getAccountsFull";
import * as workerTimers from "worker-timers";
import "@/assets/styles/mastersheet.css";

LicenseManager.setLicenseKey(
  "For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-28_November_2020_[v2]_MTYwNjUyMTYwMDAwMA==3985c15cf0de5d0a9e11cac2dc49824c"
);

@Component({
  components: {
    MastersheetEdit,
    MastersheetDraft,
    MastersheetImport,
    OmniSearchBox,
    AgGridVue,
    CustomLoadingMastersheet,
    CustomNoRowsMastersheet,
    EditIcon,
    MastersheetLink,
    MastersheetManagers,
    CountryIcon,
    UserInfo,
    TopBar,
  },
})
export default class Mastersheet extends Vue {
  public exporting = false;
  public searchInput = null;
  public accountTypes = [
    "YouTube Channel",
    "Clip Licensing",
    "Podcast",
    "China Platform",
  ];
  public selectedAccountType = "YouTube Channel";
  public selectedContractStatuses = [1];
  public selectedOffices = [];
  public accountsList: AccountFull[] = null;
  public selectedTab = 0;
  public accountIdForEdit = null;
  public createView = false;
  public cols = [];

  public filters = {};
  public displayMaximizable = false;

  public panelControlsWidth = 0;
  public panelControlsLeftOffset = 0;
  public showColumnPanelControls = false;
  public showFilterPanelControls = false;

  public showImportModal = false;

  public showDraftsModal = false;
  public draftIdForEdit = null;
  public drafts = [];

  public frameworkComponents = {
    customLoadingMastersheet: CustomLoadingMastersheet,
    customNoRowsMastersheet: CustomNoRowsMastersheet,
    editButtonRenderer: EditIcon,
    mastersheetLink: MastersheetLink,
    mastersheetManagers: MastersheetManagers,
    countryIcon: CountryIcon,
  };

  public gridOptions = {
    defaultColDef: {
      resizable: true,
      filter: true,
      sortable: true,
      menuTabs: ["filterMenuTab", "generalMenuTab"],
      floatingFilter: true,
    },
    statusBar: {
      statusPanels: [
        { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
        { statusPanel: "agTotalRowCountComponent", align: "center" },
        { statusPanel: "agFilteredRowCountComponent" },
      ],
    },
    ensureDomOrder: true,
    enableCellTextSelection: true,
    suppressCsvExport: true,
    suppressExcelExport: true,
    rowSelection: "multiple",
    onFirstDataRendered: () => {
      workerTimers.setTimeout(this.resetToPreferences, 1000);
    },
  };

  public sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressSideButtons: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
  };

  public rootCols: any[] = [
    {
      colId: "col-0",
      headerName: "",
      field: "id",
      pinned: "left",
      width: 55,
      resizable: false,
      lockPosition: true,
      floatingFilter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      lockVisible: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      colId: "col-1",
      headerName: "",
      field: "id",
      pinned: "left",
      width: 55,
      resizable: false,
      lockPosition: true,
      floatingFilter: false,
      cellRenderer: "editButtonRenderer",
      lockVisible: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
    },
    {
      colId: "col-2",
      headerName: "ID",
      field: "id",
      pinned: "left",
      width: 110,
      resizable: false,
      lockPosition: true,
      filter: "agNumberColumnFilter",
    },
  ];

  public accountCols: any[] = [];
  public youtubeCols: any[] = [];
  public clipCols: any[] = [];
  public podcastCols: any[] = [];
  public chinaPlatformCols: any[] = [];
  public creatorCols: any[] = [];
  public managersCols: any[] = [];
  public paymentCols: any[] = [];
  public socialCols: any[] = [];

  public allCols: any[] = [];
  public columnDefs: any[] = [];

  public accountsFull: AccountFull[] = null;

  public documentations = [
    { name: "ID Card" },
    { name: "Video Call screenshot" },
    { name: "Screen record" },
    { name: "NPWP for Indonesia" },
    { name: "Contract" },
  ];

  public platforms = [
    { name: "YouTube" },
    { name: "Facebook" },
    { name: "Instagram" },
    { name: "TikTok" },
    { name: "Twitter" },
    { name: "Twitch" },
    { name: "LikeApp" },
    { name: "Douyin" },
    { name: "Bilibili" },
    { name: "Bilibili Premiere" },
    { name: "Xigua" },
    { name: "Weibo" },
    { name: "Red" },
    { name: "iQIYI" },
    { name: "CHZZK" },
  ];

  public colorList = [
    "#C9E8FF",
    "#EBEEF5",
    "#FFF3C2",
    "#FDECED",
    "#D8FFE5",
    "#E0D2F7",
    "#EBEBEB",
    "#FFECD8",
    "#FFC2C9",
  ];

  public toExport = [];

  public get offices() {
    return readOffices(this.$store);
  }

  public get accountTeams() {
    return readAccountTeams(this.$store);
  }

  public get isManager() {
    return readIsManager(this.$store);
  }

  public get readonly() {
    return !this.isManager;
  }

  public get isSuper() {
    return readIsSuper(this.$store);
  }

  public get teams() {
    return readTeams(this.$store);
  }

  public get rights() {
    return readRights(this.$store);
  }

  public get contractStatuses() {
    return readContractStatuses(this.$store);
  }

  public get tiers() {
    return readTiers(this.$store);
  }

  public get networks() {
    return readNetworks(this.$store);
  }

  public get networkStatuses() {
    return readNetworkStatuses(this.$store);
  }

  public get chinaPlatformNetworkStatuses() {
    return readChinaPlatformNetworkStatuses(this.$store);
  }

  public get contentFormats() {
    return readContentFormats(this.$store);
  }

  public get contentSources() {
    return readContentSources(this.$store);
  }

  public get categories() {
    return readCategories(this.$store);
  }

  public get mainCategories() {
    return [...new Set(this.categories.map((item) => item.main_category))].map(
      (item) => {
        return { name: item };
      }
    );
  }

  public get subCategories() {
    return [...new Set(this.categories.map((item) => item.name))].map(
      (item) => {
        return { name: item };
      }
    );
  }

  public get classes() {
    return readClasses(this.$store);
  }

  public managersGetter(params) {
    const role = params.colDef.headerName;
    let managers;
    if (params.data.managers) {
      switch (role) {
        case "General Managers":
          managers = params.data.managers.filter(
            (item) => item.role === "General"
          );
          break;
        case "Contact Managers":
          managers = params.data.managers.filter(
            (item) => item.role === "Contact"
          );
          break;
        case "Optimize Managers":
          managers = params.data.managers.filter(
            (item) => item.role === "Optimize"
          );
          break;
        case "Claim Managers":
          managers = params.data.managers.filter(
            (item) => item.role === "Claim"
          );
          break;
      }
    } else {
      managers = null;
    }
    if (managers) {
      return managers.map((item) => item.first_name).join(", ");
    }
    return "";
  }

  public teamGetter(params) {
    let teams = [];
    if (params.data.managers) {
      const managers = params.data.managers;
      for (let i = 0; i < managers.length; i++) {
        if (managers[i].team) {
          teams.push(managers[i].team);
        }
      }
      teams = teams.filter((v, i, a) => a.indexOf(v) === i);
    }
    return teams;
  }

  public tabChangeCols(tabKey) {
    let onKeys, offKeys;
    let accountTypeKeys = [];
    switch (this.selectedAccountType) {
      case "YouTube Channel":
        accountTypeKeys = this.youtubeCols.map((item) => item.colId);
        break;
      case "Clip Licensing":
        accountTypeKeys = this.clipCols.map((item) => item.colId);
        break;
      case "Podcast":
        accountTypeKeys = this.podcastCols.map((item) => item.colId);
        break;
      case "China Platform":
        accountTypeKeys = this.chinaPlatformCols.map((item) => item.colId);
        break;
    }

    switch (tabKey) {
      case 0:
        onKeys = [
          ...this.accountCols.map((item) => item.colId),
          ...accountTypeKeys,
          ...this.creatorCols.map((item) => item.colId),
          ...this.managersCols.map((item) => item.colId),
          ...this.paymentCols.map((item) => item.colId),
          ...this.socialCols.map((item) => item.colId),
        ];
        break;
      case 1:
        onKeys = this.accountCols.map((item) => item.colId);
        break;
      case 2:
        onKeys = accountTypeKeys;
        break;
      case 3:
        onKeys = this.creatorCols.map((item) => item.colId);
        break;
      case 4:
        onKeys = this.managersCols.map((item) => item.colId);
        break;
      case 5:
        onKeys = this.paymentCols.map((item) => item.colId);
        break;
      case 6:
        onKeys = this.socialCols.map((item) => item.colId);
        break;
    }
    this.selectedTab = tabKey;
    const allKeys = this.cols.map((item) => item.colId);
    offKeys = allKeys.filter((item) => !onKeys.includes(item));
    this.gridOptions["columnApi"].setColumnsVisible(onKeys, true);
    this.gridOptions["columnApi"].setColumnsVisible(offKeys, false);
  }

  public reset() {
    if (this.accountsFull) {
      this.generalFilter();
    }
  }

  public tagRenderer(params) {
    const value = params.value;
    const refsList = params.refsList;
    const backgroundColor =
      this.colorList[
        refsList.map((item) => item.name).indexOf(value) % this.colorList.length
      ];
    const style = `background-color: ${backgroundColor}; padding: 0.25rem 0.4rem; border-radius: 3px;`;
    const el = document.createElement("span");
    el.style.cssText = style;
    el.innerHTML = value;
    return el;
  }

  public multiTagRenderer(params) {
    const values = params.value;
    const refsList = params.refsList;
    if (values) {
      try {
        const els = values.map((value) => {
          const backgroundColor =
            this.colorList[
              refsList.map((item) => item.name).indexOf(value) %
                this.colorList.length
            ];
          const style = `background-color: ${backgroundColor}; padding: 0.25rem 0.4rem; border-radius: 3px;`;
          const el = document.createElement("span");
          el.style.cssText = style;
          el.className = `tag-span`;
          el.innerText = value;
          return el;
        });
        const el = document.createElement("div");
        els.forEach((item) => el.appendChild(item));
        return el;
      } catch {
        console.log(values);
        console.log(typeof values);
      }
    }
    return document.createElement("span");
  }

  public percentageValueGetter(params) {}

  public channelRenderer(params) {
    const value = params.value;
    const link = `https://www.youtube.com/channel/${value}`;
    const el = document.createElement("span");
    const iconWrapper = document.createElement("a");
    iconWrapper.href = link;
    iconWrapper.target = "_blank";
    const icon = document.createElement("i");
    icon.className = "fab fa-youtube";
    icon.style.cssText = "margin-right: 4px;";
    iconWrapper.appendChild(icon);
    el.appendChild(iconWrapper);
    const text = document.createElement("span");
    text.innerText = value;
    el.appendChild(text);
    return el;
  }

  public boolRenderer(params) {
    const value = params.value;
    const className = value
      ? "fas fa-check"
      : value === false
      ? "fas fa-times"
      : "";
    const el = document.createElement("i");
    el.className = className;
    return el;
  }

  public updatePanelControlsPosition() {
    const activePanelIndex = this.showColumnPanelControls ? 0 : 1;
    const el = document.getElementsByClassName("ag-tool-panel-wrapper")[
      activePanelIndex
    ];
    let width = 0;
    let leftOffset = 0;
    if (el) {
      width = el.clientWidth + (activePanelIndex === 1 ? 8 : 0);
      leftOffset =
        document.getElementsByClassName("ag-side-buttons")[0].clientWidth + 2;
    }
    this.panelControlsWidth = width;
    this.panelControlsLeftOffset = leftOffset;
  }

  public async refreshAccounts(byPassCache = false) {
    this.accountsFull = await getAccountsFull(byPassCache);
    this.generalFilter();
  }

  public generalFilter() {
    let filteredList = this.accountsFull;
    if (filteredList) {
      filteredList = this.filterAccountType(filteredList);
      filteredList = this.filterByOffices(filteredList);
      filteredList = this.filterByContractStatus(filteredList);
    }
    this.accountsList = filteredList;
  }

  public filterAccountType(values: AccountFull[]) {
    if (this.selectedAccountType) {
      return values.filter((account) => {
        return account.type == this.selectedAccountType;
      });
    }
    return values;
  }

  public filterByOffices(values: AccountFull[]) {
    if (this.selectedOffices.length > 0) {
      return values.filter((account) => {
        return this.selectedOffices.includes(account.contract_office);
      });
    }
    return values;
  }

  public filterByContractStatus(values: AccountFull[]) {
    if (this.selectedContractStatuses.length > 0) {
      if (this.contractStatuses.length > 0) {
        const filter = this.contractStatuses
          .filter((item) => this.selectedContractStatuses.includes(item.id))
          .map((item) => item.name);
        return values.filter((account) =>
          filter.includes(account.contract_status)
        );
      }
      return values.filter(
        (account) => account.contract_status === "Contract Signed"
      );
    }
    return values;
  }

  public async openDraftsModal() {
    await this.getDrafts();
    this.showDraftsModal = true;
  }

  public editDraft(draftId: number) {
    this.createView = true;
    this.draftIdForEdit = draftId;
    this.displayMaximizable = true;
  }

  public async draftSaved(id: number) {
    this.draftIdForEdit = id;
    await this.getDrafts();
  }

  public async draftDeleted() {
    this.draftIdForEdit = null;
    await this.getDrafts();
    this.displayMaximizable = false;
  }

  public async getDrafts() {
    const response = await accountDraftsApi.listDraftAccounts();
    if (response.status === 200) {
      this.drafts = response.data;
    }
  }

  public async addNewAccount(skipDrafts = false) {
    if (!skipDrafts && this.drafts.length > 0) {
      this.openDraftsModal();
    } else {
      this.$router.push("/mastersheet/create");
    }
  }

  public openMaximizable(event) {
    this.accountIdForEdit = event;
    this.displayMaximizable = true;
    this.$router.push(`/mastersheet/${this.accountIdForEdit}`);
  }

  public openImportModal() {
    this.showImportModal = true;
  }

  public async doExport(e) {
    try {
      this.exporting = true;
      let response;
      if (e === "single") {
        response = await api.exportPage("accounts", [this.accountIdForEdit]);
      } else {
        const selected = this.gridOptions["api"].getSelectedNodes();
        if (!selected.length) {
          this["$toast"].add({
            severity: "error",
            summary: "No rows selected",
            detail: "Please select rows using the checkbox for export.",
            life: 7000,
          });
          return;
        }
        this.toExport = selected.map((item) => item.data.id);
        const fields = [];
        this.gridOptions["columnApi"]
          .getAllDisplayedColumns()
          .forEach(({ colDef }) => {
            if (!fields.includes(colDef.field)) {
              fields.push(colDef.field);
            }
          });
        response = await api.exportPage("accounts", this.toExport, fields);
      }
      if (response) {
        const date = dayjs().format("YYYY-MM-DD-HH-mm-ss");
        downloadExport(response, `accounts-export-${date}.csv`);
        this["$toast"].add({
          severity: "success",
          summary: "File exported!",
          detail:
            "Import to Google Sheets or open with a text editor for optimal operations",
          life: 3000,
        });
      } else {
        this["$toast"].add({
          severity: "error",
          summary: "Something went wrong...",
          detail: "Could not export your file",
          life: 3000,
        });
      }
    } catch (e) {
    } finally {
      this.exporting = false;
    }
  }

  public activeTab() {
    const el = this.$refs.mastersheetTabs;
    if (el) {
      const activeIndex = el["currentTab"];
      const tabs = el["tabs"];
      const activeName = tabs[activeIndex].title;
      switch (activeName) {
        case "Accounts":
          return "account-tab";
        case "YouTube":
          return "youtube-tab";
        case "Clip Licenses":
          return "clip-tab";
        case "Podcast":
          return "podcast-tab";
        case "China Platform":
          return "china-platform-tab";
        case "Creators":
          return "creator-tab";
        case "Managers":
          return "manager-tab";
        case "Payment":
          return "payment-tab";
        case "Social":
          return "social-tab";

        default:
          return "account-tab";
      }
    }
    return "account-tab";
  }

  public async confirmSave() {
    const creatorCheck =
      this.$refs["mastersheetEdit"]["accountForm"].creator_id;
    const managersCheck =
      this.$refs["mastersheetEdit"]["$refs"]["managers-edit"].members.length;
    await this.$refs["validation-observer"]["validate"]();

    // switch to first tab with error
    const errorElement = document.querySelector(
      ".form-field-error-message:not(:empty)"
    );
    if (errorElement) {
      const tabWithError = errorElement.closest(".form-input-container").id;
      if (
        (tabWithError !== "payment-tab" && tabWithError !== "social-tab") ||
        (creatorCheck && managersCheck)
      ) {
        this.$refs["mastersheetEdit"]["currentActiveTab"] = tabWithError;
      }
    } else if (!creatorCheck) {
      this.$refs["mastersheetEdit"]["currentActiveTab"] = "creator-tab";
    } else if (!managersCheck) {
      this.$refs["mastersheetEdit"]["currentActiveTab"] = "manager-tab";
    }

    const failed = !(
      this.$refs["validation-observer"]["flags"].valid &&
      creatorCheck &&
      managersCheck
    );

    if (failed) {
      this["$toast"].add({
        severity: "error",
        summary: "Form not valid",
        detail:
          "Make sure to fill out required fields and follow the correct formats across all tabs.",
        life: 5000,
      });
    } else {
      this.$refs.mastersheetEdit["confirmSave"]();
    }
  }

  public dateComparator(filterLocalDateAtMidnight, cellValue) {
    const dateAsString = cellValue;

    if (dateAsString == null) {
      return 0;
    }

    // In the example application, dates are stored as dd/mm/yyyy
    // We create a Date object for comparison against the filter date
    const dateParts = dateAsString.split("-");
    const day = Number(dateParts[2]);
    const month = Number(dateParts[1]) - 1;
    const year = Number(dateParts[0]);
    const cellDate = new Date(year, month, day);

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    } else {
      return 0;
    }
  }

  public resetToDefaults() {
    try {
      const allKeys = this.allCols
        .map((item) => item.children)
        .flat()
        .map((item) => item.colId);
      this.gridOptions["columnApi"].setColumnsVisible(allKeys, true);
      this.tabChangeCols(0);
    } catch (err) {
      this.tabChangeCols(0);
      this["$toast"].add({
        severity: "error",
        summary: "Could not restore correctly!.",
        life: 3000,
      });
    }
  }

  public saveColumnsPrefs() {
    const columns: any[] =
      this.gridOptions["columnApi"].getAllDisplayedColumns();
    const columnIds = columns.map((item) => item.colId);
    localStorage.setItem("columnsPrefs", JSON.stringify(columnIds));
    this["$toast"].add({
      severity: "success",
      summary: "Column preferences saved!",
      life: 3000,
    });
  }

  public resetToPreferences() {
    try {
      const allKeys = [...this.cols, ...this.rootCols].map(
        (item) => item.colId
      );
      const onKeys = JSON.parse(localStorage.getItem("columnsPrefs")) || [];
      if (!onKeys.length) {
        return;
      }
      const offKeys = allKeys.filter((item) => !onKeys.includes(item));
      this.gridOptions["columnApi"].setColumnsVisible(onKeys, true);
      this.gridOptions["columnApi"].setColumnsVisible(offKeys, false);
      this.hideUnnecessaryCols();
    } catch (err) {
      this.tabChangeCols(0);
      this["$toast"].add({
        severity: "error",
        summary: "Could not restore correctly! Using app defaults.",
        life: 3000,
      });
    }
  }

  public async switchToEditMode(accountId) {
    if (this.draftIdForEdit) {
      this.showDraftsModal = false;
      try {
        await accountDraftsApi.deleteDraftAccount(this.draftIdForEdit);
        await this.getDrafts();
      } catch (e) {
        console.log(e);
      }
      this.draftIdForEdit = null;
    }
    this.accountIdForEdit = accountId;
    this.createView = false;
  }

  private toggleCols(headerName: string, show: boolean) {
    this.columnDefs = this.columnDefs.map((group) => {
      if (group.headerName === headerName) {
        group.children.forEach((column) => {
          column.suppressColumnsToolPanel = !show;
          column.suppressFiltersToolPanel = !show;
          if (this.selectedTab === 0 || this.selectedTab === 2 || !show) {
            column.hide = !show;
          }
        });
        group.suppressColumnsToolPanel = !show;
        group.suppressFiltersToolPanel = !show;
      }
      return group;
    });
  }

  @Watch("selectedAccountType")
  async changeAccountType() {
    switch (this.selectedAccountType) {
      case "YouTube Channel":
        await this.$router.push("/mastersheet/youtube");
        break;
      case "Clip Licensing":
        await this.$router.push("/mastersheet/clip-licensing");
        break;
      case "Podcast":
        await this.$router.push("/mastersheet/podcast");
        break;
      case "China Platform":
        await this.$router.push("/mastersheet/china-platform");
        break;
    }
  }

  @Watch("selectedAccountType", { immediate: true })
  async hideUnnecessaryCols() {
    switch (this.selectedAccountType) {
      case "YouTube Channel":
        this.toggleCols("YouTube", true);
        this.toggleCols("Clip License", false);
        this.toggleCols("Podcast", false);
        this.toggleCols("China Platform", false);
        break;
      case "Clip Licensing":
        this.toggleCols("YouTube", false);
        this.toggleCols("Clip License", true);
        this.toggleCols("Podcast", false);
        this.toggleCols("China Platform", false);
        break;
      case "Podcast":
        this.toggleCols("YouTube", false);
        this.toggleCols("Clip License", false);
        this.toggleCols("Podcast", true);
        this.toggleCols("China Platform", false);
        break;
      case "China Platform":
        this.toggleCols("YouTube", false);
        this.toggleCols("Clip License", false);
        this.toggleCols("Podcast", false);
        this.toggleCols("China Platform", true);
        break;
    }
  }

  @Watch("displayMaximizable")
  public async changeUrl() {
    if (!this.displayMaximizable) {
      // TODO: utilize this var more
      const urlMapping = {
        "YouTube Channel": "youtube",
        "Clip Licensing": "clip-licensing",
        Podcast: "podcast",
        "China Platform": "china-platform",
      };
      if (
        this.$route.path !==
        `/mastersheet/${urlMapping[this.selectedAccountType]}`
      ) {
        this.$router.replace(
          `/mastersheet/${urlMapping[this.selectedAccountType]}`
        );
      }
      this.draftIdForEdit = null;
      this.createView = false;
      this.accountsFull = [];
      this.accountsFull = await getAccountsFull();
      this.reset();
    }
  }

  @Watch("$route", { immediate: true, deep: true })
  public straightToDialog(newVal) {
    if (newVal.meta.displayMaximizable) {
      if (newVal.meta.createView) {
        this.createView = newVal.meta.createView;
      } else {
        this.accountIdForEdit = newVal.params.id;
      }
      this.displayMaximizable = newVal.meta.displayMaximizable;
    }
  }

  public beforeRouteEnter(to, from, next) {
    if (to.path == "/mastersheet") {
      next({ path: "/mastersheet/youtube" });
    }
    next();
  }

  public beforeRouteUpdate(to, from, next) {
    if (to.meta.selectedAccountType) {
      this.selectedAccountType = to.meta.selectedAccountType;
    }
    next();
    this.generalFilter();
  }

  public created() {
    if (this.$route.meta.selectedAccountType) {
      this.selectedAccountType = this.$route.meta.selectedAccountType;
    }
    this.generalFilter();
    try {
      if (this.$tours["myTour"] && this.$tours["myTour"].isRunning) {
        if (this.$route.hash === "#tour-next") this.$tours["myTour"].nextStep();
        if (this.$route.hash === "#tour-prev")
          this.$tours["myTour"].previousStep();
      }
    } catch (e) {
      console.info(e);
    }
  }

  public async beforeMount() {
    await Promise.all([
      dispatchListRights(this.$store),
      dispatchListContractStatuses(this.$store),
      dispatchListOffices(this.$store),
      dispatchListAccountTeams(this.$store),
      dispatchListTeams(this.$store),
      dispatchListTiers(this.$store),
      dispatchListNetworks(this.$store),
      dispatchListNetworkStatuses(this.$store),
      dispatchListChinaPlatformNetworkStatuses(this.$store),
      dispatchListCategories(this.$store),
      dispatchListClasses(this.$store),
      dispatchListContentFormats(this.$store),
      dispatchListContentSources(this.$store),
      dispatchListPodcastStatuses(this.$store),
      dispatchListPodcastGenres(this.$store),
      dispatchGetSearchCreatorFuse(this.$store),
    ]);

    this.selectedOffices = this.offices.map((item) => item.name);

    this.accountCols = [
      {
        colId: "col-4",
        headerName: "Name",
        field: "name",
        width: 200,
        resizable: false,
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-3",
        headerName: "Dash. User ID",
        field: "dashboard_id",
        width: 140,
        resizable: true,
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-5",
        headerName: "Right",
        field: "right",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.rights },
      },
      {
        colId: "col-6",
        headerName: "Tier",
        field: "tier",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.tiers },
      },
      {
        colId: "col-7",
        headerName: "Contract Office",
        field: "contract_office",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.offices },
      },
      {
        colId: "col-8",
        headerName: "Contract Status",
        field: "contract_status",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.contractStatuses },
      },
      {
        colId: "col-122",
        headerName: "Team",
        field: "team",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.accountTeams },
      },
      {
        colId: "col-9",
        headerName: "Contract Date",
        field: "contract_date",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: this.dateComparator,
        },
      },
      {
        colId: "col-10",
        headerName: "Expiration Date",
        field: "expiration_date",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: this.dateComparator,
        },
      },
      {
        colId: "col-11",
        headerName: "First Contact Date",
        field: "first_contact_date",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: this.dateComparator,
        },
      },
      { colId: "col-12", headerName: "Previous MCN", field: "previous_mcn" },
      { colId: "col-13", headerName: "Status (JP)", field: "status" },
      {
        colId: "col-14",
        headerName: "Yomi (JP)",
        field: "yomi",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-15",
        headerName: "Class (JP)",
        field: "class_",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.classes },
      },
      {
        colId: "col-16",
        headerName: "Platforms",
        field: "platforms",
        cellRenderer: this.multiTagRenderer,
        cellRendererParams: { refsList: this.platforms },
      },
      {
        colId: "col-17",
        headerName: "Contact Email",
        field: "contact_email",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-18",
        headerName: "Initial Contract Date",
        field: "initial_contract_date",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: this.dateComparator,
        },
      },
      {
        colId: "col-19",
        headerName: "Third Party",
        field: "third_party",
      },
      {
        colId: "col-94",
        headerName: "Partner Organization",
        field: "partner_organization",
      },
    ];

    this.youtubeCols = [
      {
        colId: "col-20",
        headerName: "Channel ID",
        field: "youtube_channel__id",
        filter: "agTextColumnFilter",
        cellRenderer: this.channelRenderer,
      },
      {
        colId: "col-21",
        headerName: "Channel Name",
        field: "youtube_channel__channel_name",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-22",
        headerName: "Network",
        field: "youtube_channel__network",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.networks },
      },
      {
        colId: "col-23",
        headerName: "Linked Date",
        field: "youtube_channel__linked_date",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: this.dateComparator,
        },
      },
      {
        colId: "col-24",
        headerName: "Network Status",
        field: "youtube_channel__network_status",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.networkStatuses },
      },
      {
        colId: "col-25",
        headerName: "Unlinked Date",
        field: "youtube_channel__unlinked_date",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: this.dateComparator,
        },
      },
      {
        colId: "col-26",
        headerName: "Content Formats",
        field: "youtube_channel__content_formats",
        cellRenderer: this.multiTagRenderer,
        cellRendererParams: { refsList: this.contentFormats },
      },
      {
        colId: "col-27",
        headerName: "Content Source",
        field: "youtube_channel__content_source",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.contentSources },
      },
      {
        colId: "col-28",
        headerName: "Main Category",
        field: "youtube_channel__main_category_1",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.mainCategories },
      },
      {
        colId: "col-29",
        headerName: "Sub Category 1",
        field: "youtube_channel__sub_category_1",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.subCategories },
      },
      {
        colId: "col-30",
        headerName: "Sub Category 2",
        field: "youtube_channel__sub_category_2",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.subCategories },
      },
      {
        colId: "col-31",
        headerName: "Custom Category",
        field: "youtube_channel__custom_category",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-32",
        headerName: "Views",
        field: "youtube_channel__views",
        filter: "agNumberColumnFilter",
        comparator: (a, b) => a - b,
      },
      {
        colId: "col-33",
        headerName: "Subscribers",
        field: "youtube_channel__subscribers",
        filter: "agNumberColumnFilter",
        comparator: (a, b) => a - b,
      },
      {
        colId: "col-34",
        headerName: "Subs at signing",
        field: "youtube_channel__subs_at_signing",
        filter: "agNumberColumnFilter",
        comparator: (a, b) => a - b,
      },
      {
        colId: "col-35",
        headerName: "Monetization Start Date",
        field: "youtube_channel__monetization_start_date",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: this.dateComparator,
        },
      },
      {
        colId: "col-36",
        headerName: "Custom ID",
        field: "youtube_channel__custom_id",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-120",
        headerName: "Thumbnail",
        field: "youtube_channel__thumbnail_url",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
    ];

    this.clipCols = [
      {
        colId: "col-37",
        headerName: "CMS",
        field: "clip_license__network",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.networks },
      },
      {
        colId: "col-38",
        headerName: "Buying Price",
        field: "clip_license__buying_price",
        filter: "agNumberColumnFilter",
      },
      {
        colId: "col-39",
        headerName: "Link to CLUB Folder",
        field: "clip_license__link_to_club_folder",
        filter: "agTextColumnFilter",
        cellRenderer: "mastersheetLink",
      },
      {
        colId: "col-40",
        headerName: "Documentation",
        field: "clip_license__documentation",
        filter: "agTextColumnFilter",
        cellRenderer: this.multiTagRenderer,
        cellRendererParams: { refsList: this.documentations },
      },
    ];

    this.podcastCols = [
      {
        colId: "col-86",
        headerName: "Description",
        field: "podcast__description",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-87",
        headerName: "RSS Link",
        field: "podcast__rss_link",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-88",
        headerName: "Language",
        field: "podcast__language",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-89",
        headerName: "Login Email",
        field: "podcast__login_email",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-90",
        headerName: "Login Password",
        field: "podcast__login_password",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-91",
        headerName: "Podcast Status",
        field: "podcast__status",
      },
      {
        colId: "col-92",
        headerName: "Main Genre",
        field: "podcast__main_genre",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-93",
        headerName: "Sub Genre",
        field: "podcast__sub_genre",
        filter: "agTextColumnFilter",
      },
    ];

    this.chinaPlatformCols = [
      {
        colId: "col-95",
        headerName: "Channel Link",
        field: "china_platform__channel_link",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-96",
        headerName: "Network Status",
        field: "china_platform__network_status",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.chinaPlatformNetworkStatuses },
      },
      {
        colId: "col-97",
        headerName: "Linked Date",
        field: "china_platform__linked_date",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: this.dateComparator,
        },
      },
      {
        colId: "col-98",
        headerName: "Unlinked Date",
        field: "china_platform__unlinked_date",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: this.dateComparator,
        },
      },
      {
        colId: "col-99",
        headerName: "Content Formats",
        field: "china_platform__content_formats",
        cellRenderer: this.multiTagRenderer,
        cellRendererParams: { refsList: this.contentFormats },
      },
      {
        colId: "col-100",
        headerName: "Content Source",
        field: "china_platform__content_source",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.contentSources },
      },
      {
        colId: "col-101",
        headerName: "Main Category",
        field: "china_platform__main_category_1",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.mainCategories },
      },
      {
        colId: "col-102",
        headerName: "Sub Category 1",
        field: "china_platform__sub_category_1",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.subCategories },
      },
      {
        colId: "col-103",
        headerName: "Sub Category 2",
        field: "china_platform__sub_category_2",
        cellRenderer: this.tagRenderer,
        cellRendererParams: { refsList: this.subCategories },
      },
      {
        colId: "col-104",
        headerName: "Custom Category",
        field: "china_platform__custom_category",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-105",
        headerName: "Views",
        field: "china_platform__views",
        filter: "agNumberColumnFilter",
        comparator: (a, b) => a - b,
      },
      {
        colId: "col-106",
        headerName: "Subscribers",
        field: "china_platform__subscribers",
        filter: "agNumberColumnFilter",
        comparator: (a, b) => a - b,
      },
      {
        colId: "col-107",
        headerName: "Subs at signing",
        field: "china_platform__subs_at_signing",
        filter: "agNumberColumnFilter",
        comparator: (a, b) => a - b,
      },
    ];

    this.creatorCols = [
      {
        colId: "col-41",
        headerName: "Creator ID",
        field: "creator__id",
        filter: "agNumberColumnFilter",
        width: 120,
      },
      { colId: "col-42", headerName: "Type", field: "creator__type" },
      {
        colId: "col-43",
        headerName: "First Name",
        field: "creator__first_name",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-44",
        headerName: "Last Name",
        field: "creator__last_name",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-45",
        headerName: "Email",
        field: "creator__email",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-46",
        headerName: "Termination Scheduled",
        field: "creator__termination_scheduled",
        cellRenderer: this.boolRenderer,
      },
      {
        colId: "col-47",
        headerName: "Nationality",
        field: "creator__nationality",
        cellRenderer: "countryIcon",
      },
      {
        colId: "col-48",
        headerName: "Residence Country",
        field: "creator__residence_country",
        cellRenderer: "countryIcon",
      },
      {
        colId: "col-49",
        headerName: "Residence Notes",
        field: "creator__residence_country_details",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-50",
        headerName: "Birthday",
        field: "creator__birthdate",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: this.dateComparator,
        },
      },
      { colId: "col-51", headerName: "Crew", field: "creator__crew" },
      {
        colId: "col-52",
        headerName: "Home Address",
        field: "creator__home_address",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-53",
        headerName: "Phone Number",
        field: "creator__phone_number",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-54",
        headerName: "Second Language",
        field: "creator__second_language",
      },
      {
        colId: "col-55",
        headerName: "Marketing Allowed",
        field: "creator__marketing_allowed",
        cellRenderer: this.boolRenderer,
      },
      {
        colId: "col-56",
        headerName: "Countries of Interest",
        field: "creator__countries_of_interest",
      },
      {
        colId: "col-57",
        headerName: "Brand Deals of Interest",
        field: "creator__brand_deals_of_interest",
      },
    ];

    this.managersCols = [
      {
        colId: "col-58",
        headerName: "General Managers",
        valueGetter: this.managersGetter,
        filter: "agTextColumnFilter",
        field: "creator.general_manager",
      },
      {
        colId: "col-59",
        headerName: "Optimize Managers",
        valueGetter: this.managersGetter,
        filter: "agTextColumnFilter",
        field: "creator.optimize_manager",
      },
      {
        colId: "col-60",
        headerName: "Claim Managers",
        valueGetter: this.managersGetter,
        filter: "agTextColumnFilter",
        field: "creator.claim_manager",
      },
      {
        colId: "col-61",
        headerName: "Contact Managers",
        valueGetter: this.managersGetter,
        filter: "agTextColumnFilter",
        field: "creator.contact_manager",
      },
      {
        colId: "col-62",
        headerName: "Managers Team",
        valueGetter: this.teamGetter,
        cellRenderer: this.multiTagRenderer,
        cellRendererParams: { refsList: this.teams },
        field: "creator.managers_team",
      },
    ];

    this.paymentCols = [
      {
        colId: "col-63",
        headerName: "Split (Creator)",
        field: "splits.creator",
        valueGetter: (params) => `${(params.data.splits?.creator ?? 0) * 100}%`,
        filter: "agNumberColumnFilter",
      },
      {
        colId: "col-64",
        headerName: "Split (Collab)",
        field: "splits.collab",
        valueGetter: (params) => `${(params.data.splits?.collab ?? 0) * 100}%`,
        filter: "agNumberColumnFilter",
      },
      {
        colId: "col-65",
        headerName: "Split (Third Party)",
        field: "splits.third_party",
        valueGetter: (params) =>
          `${(params.data.splits?.third_party ?? 0) * 100}%`,
        filter: "agNumberColumnFilter",
      },
      {
        colId: "col-66",
        headerName: "Bank Name",
        field: "payment_info.bank_name",
      },
      {
        colId: "col-67",
        headerName: "Swift Code",
        field: "payment_info.swift_code",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-68",
        headerName: "Bank Branch",
        field: "payment_info.bank_branch",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-69",
        headerName: "Bank Address",
        field: "payment_info.bank_address",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-70",
        headerName: "Paypal Email",
        field: "payment_info.paypal_email",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-701",
        headerName: "Paypal URL",
        field: "payment_info.paypal_url",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-71",
        headerName: "JP Post No.",
        field: "payment_info.jp_post_number",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-72",
        headerName: "Payment Issues",
        field: "payment_info.payment_issues",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-73",
        headerName: "Payment Method",
        field: "payment_info.payment_method",
      },
      {
        colId: "col-74",
        headerName: "Bank Branch Code",
        field: "payment_info.bank_branch_code",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-75",
        headerName: "Beneficiary Name",
        field: "payment_info.beneficiary_name",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-76",
        headerName: "Bank Account Name",
        field: "payment_info.bank_account_name",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-77",
        headerName: "Bank Account No.",
        field: "payment_info.bank_account_number",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-78",
        headerName: "Payment Method Effective Date",
        field: "payment_info.payment_method_effective_date",
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: this.dateComparator,
        },
      },
      {
        colId: "col-108",
        headerName: "Alipay",
        field: "payment_info.alipay",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-109",
        headerName: "Alipay Email",
        field: "payment_info.alipay_email",
        filter: "agTextColumnFilter",
      },
    ];

    this.socialCols = [
      {
        colId: "col-79",
        headerName: "YouTube",
        field: "social_media.youtube",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-80",
        headerName: "Facebook",
        field: "social_media.facebook",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-81",
        headerName: "Instagram",
        field: "social_media.instagram",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-82",
        headerName: "Twitter",
        field: "social_media.twitter",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-83",
        headerName: "TikTok",
        field: "social_media.tiktok",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-111",
        headerName: "Twitch",
        field: "social_media.twitch",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-112",
        headerName: "LikeApp",
        field: "social_media.likeapp",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-113",
        headerName: "Douyin",
        field: "social_media.douyin",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-114",
        headerName: "Bilibili",
        field: "social_media.bilibili",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-115",
        headerName: "Bilibili Premiere",
        field: "social_media.bilibili_premiere",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-116",
        headerName: "Xigua",
        field: "social_media.xigua",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-117",
        headerName: "Weibo",
        field: "social_media.weibo",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-118",
        headerName: "Red",
        field: "social_media.red",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-119",
        headerName: "iQIYI",
        field: "social_media.iqiyi",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-121",
        headerName: "CHZZK",
        field: "social_media.chzzk",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-84",
        headerName: "Live Streaming",
        field: "social_media.live_streaming",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
      {
        colId: "col-85",
        headerName: "Other",
        field: "social_media.other",
        cellRenderer: "mastersheetLink",
        filter: "agTextColumnFilter",
      },
    ];

    this.allCols = [
      {
        headerName: "Base",
        marryChildren: true,
        openByDefault: true,
        children: this.rootCols,
      },
      {
        headerName: "Account",
        openByDefault: true,
        children: this.accountCols,
      },
      {
        headerName: "YouTube",
        openByDefault: true,
        children: this.youtubeCols,
      },
      {
        headerName: "Clip License",
        openByDefault: true,
        children: this.clipCols,
      },
      {
        headerName: "Podcast",
        openByDefault: true,
        children: this.podcastCols,
      },
      {
        headerName: "China Platform",
        openByDefault: true,
        children: this.chinaPlatformCols,
      },
      {
        headerName: "Creator",
        openByDefault: true,
        children: this.creatorCols,
      },
      {
        headerName: "Managers",
        openByDefault: true,
        children: this.managersCols,
      },
      {
        headerName: "Payment",
        openByDefault: true,
        children: this.paymentCols,
      },
      {
        headerName: "Social",
        openByDefault: true,
        children: this.socialCols,
      },
    ];

    this.columnDefs = [...this.allCols];
    this.hideUnnecessaryCols();
    let cols = [];
    this.columnDefs.forEach((item) => (cols = [...cols, ...item.children]));
    this.cols = cols.filter((item) => !this.rootCols.includes(item));

    // drafts
    await this.getDrafts();
  }

  public async mounted() {
    const path = this.$route.path.split("/").filter((item) => item);
    if (
      path[0] == "mastersheet" &&
      ["youtube", "clip-licensing", "podcast", "china-platform"].includes(
        path[1]
      )
    ) {
      this.accountsFull = await getAccountsFull();
      this.reset();
    }

    const panelToggleButtons =
      document.getElementsByClassName("ag-side-button");
    Array.from(panelToggleButtons).forEach((button, index) => {
      button.addEventListener("click", () => {
        if (index === 0) {
          this.showColumnPanelControls = !this.showColumnPanelControls;
          this.showFilterPanelControls = false;
        } else {
          this.showFilterPanelControls = !this.showFilterPanelControls;
          this.showColumnPanelControls = false;
        }
        if (this.showColumnPanelControls || this.showFilterPanelControls) {
          this.updatePanelControlsPosition();
        }
      });
    });
  }
}
