


















































































































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";

@Component
export default class SocialEdit extends Vue {
  @Prop() public readonly createView!: boolean;
  @Prop() public readonly readonly!: boolean;

  @PropSync("social") public socialForm!: any;

  public mounted() {
    if (!this.createView) {
      const accountId = parseInt(this.$route.params.id, 0);
      window["analytics"]["page"](`/mastersheet/${accountId}/social`);
    }
  }
}
