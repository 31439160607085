







import { Component, Prop, Vue } from "vue-property-decorator";

import RiseLoader from "vue-spinner/src/RiseLoader.vue";

@Component({
  components: {
    RiseLoader
  }
})
export default class CustomLoadingMastersheet extends Vue {}
