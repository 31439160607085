

























































import FileUpload from "primevue/fileupload";
import { Component, Prop, PropSync, Watch, Vue } from "vue-property-decorator";
import { api } from "@/api";
import "@/assets/styles/account-dialog.css";

@Component({
  components: {
    FileUpload,
  },
})
export default class MastersheetImport extends Vue {
  @Prop() public readonly defaultAccountType: string;

  @PropSync("isOpen", { type: Boolean })
  public isOpenSync!: boolean;

  public typeOptions = [
    "YouTube Channel",
    "Clip Licensing",
    "Podcast",
    "China Platform",
  ];
  public accountType = "YouTube Channel";
  public isLoading = false;
  public uploaderKey = 0;

  @Watch("isOpen")
  public resetAccountType() {
    this.accountType = this.defaultAccountType;
  }

  public get templateUrl() {
    const mapping = {
      "YouTube Channel": "youtube_channel",
      "Clip Licensing": "clip_licensing",
      Podcast: "podcast",
      "China Platform": "china_platform",
    };
    return `/api/static/templates/accounts/${mapping[this.accountType]}.xlsx`;
  }

  public hide() {
    this.isOpenSync = false;
  }

  public async importFile(event) {
    this.isLoading = true;
    const file = event.files[0];
    const response = await api.importAccounts(this.accountType, file);
    if (response) {
      if (response.data.total_records_processed > 0) {
        if (response.data.skipped_records.length === 0) {
          this.addToast("success", "Your file has been successfully imported.");
          this.isOpenSync = false;
        } else {
          this.addToast(
            "warn",
            "Some rows have been skipped due to missing or invalid fields."
          );
        }
        this.$emit("import-success");
      } else {
        this.addToast(
          "error",
          "No record has been processed. Please make sure you use the correct template."
        );
      }
    } else {
      this.addToast("error", "Could not import file.");
    }
    this.uploaderKey++;
    this.isLoading = false;
  }

  private addToast(type, message) {
    this.$toast.add({
      severity: type,
      summary: message,
      life: 5000,
      group: "bl",
    });
  }

  public addEventListeners() {
    const dropzone = document.getElementsByClassName("p-fileupload-content")[0];
    dropzone.addEventListener("dragover", (event) => {
      dropzone.classList.add("dragging");
    });
    dropzone.addEventListener("dragleave", (event) => {
      dropzone.classList.remove("dragging");
    });
    dropzone.addEventListener("drop", (event) => {
      dropzone.classList.remove("dragging");
    });
  }
}
