
















































































































































































































































































































































import {
  Component,
  Vue,
  Prop,
  Watch,
  PropSync,
  Emit,
} from "vue-property-decorator";
import { accountDraftsApi } from "@/apis/account-drafts";
import AccountEdit from "@/views/mastersheet/MastersheetEdit/AccountEdit.vue";
import CreatorEdit from "@/views/mastersheet/MastersheetEdit/CreatorEdit.vue";
import ClipEdit from "@/views/mastersheet/MastersheetEdit/ClipEdit.vue";
import PodcastEdit from "@/views/mastersheet/MastersheetEdit/PodcastEdit.vue";
import ChinaPlatformEdit from "@/views/mastersheet/MastersheetEdit/ChinaPlatformEdit.vue";
import PaymentEdit from "@/views/mastersheet/MastersheetEdit/PaymentEdit.vue";
import YoutubeEdit from "@/views/mastersheet/MastersheetEdit/YoutubeEdit.vue";
import SocialEdit from "@/views/mastersheet/MastersheetEdit/SocialEdit.vue";
import CategoryEdit from "@/views/mastersheet/MastersheetEdit/CategoryEdit.vue";
import FilesEdit from "@/views/mastersheet/MastersheetEdit/FilesEdit.vue";
import CommentsEdit from "@/views/mastersheet/MastersheetEdit/CommentsEdit.vue";
import ManagersEdit from "@/views/mastersheet/MastersheetEdit/ManagersEdit.vue";
import { DashboardUser } from "@/interfaces";
import {
  dispatchCreateAccount,
  dispatchGetAccountFull,
  dispatchUpdateAccount,
  dispatchDeleteAccount,
} from "@/store/account/actions";
import { readAccountFull } from "@/store/account/getters";

@Component({
  components: {
    AccountEdit,
    CreatorEdit,
    ClipEdit,
    PodcastEdit,
    ChinaPlatformEdit,
    PaymentEdit,
    YoutubeEdit,
    SocialEdit,
    CategoryEdit,
    FilesEdit,
    CommentsEdit,
    ManagersEdit,
  },
})
export default class MastersheetEdit extends Vue {
  @Prop() public readonly activeTab!: string;
  @Prop() public readonly accountId!: number;
  @Prop() public readonly createView!: boolean;
  @Prop() public readonly readonly!: boolean;
  @Prop() public readonly exporting!: boolean;
  @Prop() public readonly draftId!: number;

  @PropSync("displayMaximizable") public displayMaximizableSync!: boolean;

  public currentActiveTab = !this.createView ? this.activeTab : "account-tab";
  public accountForm: any = {
    youtube_channel: {},
    clip_license: {},
    payment_info: {},
    podcast: {},
    china_platform: {},
    splits: {
      creator: 0,
      collab: 0,
      third_party: 0,
    },
    social_media: {},
  };
  public youtubeThumbnailUrl: string | null = null;
  public dashboardUser: DashboardUser | null = null;
  public isDashboardUserLoading = false;
  public saving: boolean = false;
  public deleting: boolean = false;
  public savingDraft: boolean = false;
  public deletingDraft: boolean = false;

  public get account() {
    return readAccountFull(this.$store);
  }

  public get linkedChannels() {
    return this.dashboardUser?.youtube_channels ?? [];
  }

  public async close() {
    this.displayMaximizableSync = false;
  }

  public toggleClass(e) {
    if (this.currentActiveTab === e) {
      return "router-link-active";
    }
    return "";
  }

  public async saveDraft() {
    if (!this.createView) {
      return;
    }

    this.savingDraft = true;

    const managers = this.$refs["managers-edit"]["members"].map((member) => {
      return {
        manager_id: member.id,
        role_id: member.role_id || 4,
        split: member.split,
      };
    });
    const draft = {
      data: {
        account: this.accountForm,
        managers,
      },
    };

    let response = null;
    if (this.draftId === null) {
      response = await accountDraftsApi.createDraftAccount(draft);
    } else {
      response = await accountDraftsApi.updateDraftAccount(this.draftId, draft);
    }

    if (response && response.status === 200) {
      this.$toast.add({
        severity: "success",
        summary: `Draft successfully ${this.draftId ? "updated" : "saved"}.`,
        detail: "You may complete it within 7 days from now.",
        life: 6000,
      });
      this.$emit("draft-saved", response.data.id);
    } else {
      this.$toast.add({
        severity: "error",
        summary: "Could not save draft",
        detail: "Something went wrong.",
        life: 6000,
      });
    }

    this.savingDraft = false;
  }

  public async deleteDraft() {
    if (!this.createView && !this.draftId) {
      return;
    }

    this.deletingDraft = true;

    const response = await accountDraftsApi.deleteDraftAccount(this.draftId);
    if (response && response.status === 200) {
      setTimeout(() => {
        this.$toast.add({
          severity: "success",
          summary: "Draft successfully deleted.",
          life: 6000,
        });
      }, 100);
      this.$emit("draft-deleted");
    } else {
      this.$toast.add({
        severity: "error",
        summary: "Could not delete draft",
        detail: "Something went wrong.",
        life: 6000,
      });
    }

    this.deletingDraft = false;
  }

  public confirmSave() {
    const t = this;
    const hasDuplicate =
      t.$refs["youtube-edit"] && t.$refs["youtube-edit"]["isDuplicate"];
    this["$dialog"]
      .confirm(
        hasDuplicate
          ? "An account for this channel ID already exists. Are you sure you wish to continue?"
          : "Are you sure you want to save the changes?",
        this["$saveOptions"]
      )
      .then(async function () {
        t.saving = true;

        // reset empty date fields to null
        if (t.accountForm.expiration_date === "") {
          t.accountForm.expiration_date = null;
        }
        if (t.accountForm.initial_contract_date === "") {
          t.accountForm.initial_contract_date = null;
        }
        if (t.accountForm.first_contact_date === "") {
          t.accountForm.first_contact_date = null;
        }
        if (t.accountForm.youtube_channel?.unlinked_date === "") {
          t.accountForm.youtube_channel.unlinked_date = null;
        }
        if (t.accountForm.youtube_channel?.monetization_start_date === "") {
          t.accountForm.youtube_channel.monetization_start_date = null;
        }

        // reset numeric feilds to null
        if (t.accountForm.dashboard_id === "") {
          t.accountForm.dashboard_id = null;
        }
        if (t.accountForm.youtube_channel?.subs_at_signing === "") {
          t.accountForm.youtube_channel.subs_at_signing = null;
        }
        if (t.accountForm.youtube_channel?.subscribers === "") {
          t.accountForm.youtube_channel.subscribers = null;
        }
        if (t.accountForm.youtube_channel?.views === "") {
          t.accountForm.youtube_channel.views = null;
        }
        if (t.accountForm.china_platform?.subs_at_signing === "") {
          t.accountForm.china_platform.subs_at_signing = null;
        }
        if (t.accountForm.china_platform?.subscribers === "") {
          t.accountForm.china_platform.subscribers = null;
        }
        if (t.accountForm.china_platform?.views === "") {
          t.accountForm.china_platform.views = null;
        }

        if (t.createView) {
          const response = await dispatchCreateAccount(t.$store, {
            data: t.accountForm,
          });
          if (response) {
            const newId = response.id;
            const newResponse = await t.$refs["managers-edit"]["saveManagers"](
              newId
            );
            if (newResponse) {
              await t.$router.push(`/mastersheet/${newId}`);
              t["$toast"].add({
                severity: "success",
                detail:
                  "Account successfully added. You may now upload or link files to it.",
                life: 6000,
              });
            }
            t.accountForm.id = newId;
            t.$emit("refresh-accounts");
            t.$emit("new-account-created", newId);
          } else {
            t["$toast"].add({
              severity: "error",
              summary: "Could not create account",
              detail: "Something went wrong",
              life: 6000,
            });
          }
        } else {
          const response = await dispatchUpdateAccount(t.$store, {
            id: t.accountForm.id,
            data: t.accountForm,
          });
          if (response) {
            const response = await t.$refs["managers-edit"]["saveManagers"]();
            if (response) {
              t["$toast"].add({
                severity: "success",
                detail: "Account successfully updated.",
                life: 3000,
              });
            } else {
              t["$toast"].add({
                severity: "error",
                summary: "Could not update account's managers",
                detail: "Something went wrong.",
                life: 6000,
              });
            }
            t.$emit("refresh-accounts");
          } else {
            t["$toast"].add({
              severity: "error",
              summary: "Could not update account",
              detail: "Something went wrong.",
              life: 6000,
            });
          }
        }
        t.saving = false;
      });
  }

  public managerRoleNotice() {
    this["$toast"].removeGroup("bl");
    this["$toast"].add({
      severity: "info",
      summary: "About roles editing",
      group: "bl",
      detail:
        "Only users with Super Admin permission can change the roles of managers here.",
    });
  }

  public async confirmDelete() {
    const t = this;
    this["$dialog"]
      .confirm(
        "Make sure you only delete accounts with incorrect information.\n In case the account's contract is only terminated, please mark the account as 'Terminated' in the Contract Status instead.",
        this["$deleteOptions"]
      )
      .then(async function () {
        t.deleting = true;
        const response = await dispatchDeleteAccount(t.$store, {
          id: t.accountId,
        });
        if (response && response.status === 200) {
          // TODO: proper navigation needed
          t.$emit("refresh-accounts", true);
          t.displayMaximizableSync = false;
          setTimeout(() => {
            t.$toast.add({
              severity: "success",
              detail: "Account successfully deleted.",
              life: 3000,
            });
          }, 100);
        } else {
          t.$toast.add({
            severity: "error",
            summary: "Could not delete account",
            detail: "Something went wrong. Please reach out to support.",
            life: 3000,
          });
        }
        t.deleting = false;
      });
  }

  public setDashboardUserIsLoading() {
    this.isDashboardUserLoading = true;
  }

  public updateDashboardUser(dashboardUser) {
    this.dashboardUser = dashboardUser;
    this.isDashboardUserLoading = false;
  }

  @Emit("do-export")
  public doExport(e) {
    return;
  }

  @Watch("accountForm.youtube_channel.id")
  public updatePaymentInfo() {
    if (
      !this.createView ||
      !this.accountForm.youtube_channel.id ||
      !this.dashboardUser
    ) {
      return;
    }
    const selectedChannel = this.dashboardUser.youtube_channels.find(
      (channel) =>
        channel.youtube_channel_id === this.accountForm.youtube_channel.id
    );

    if (selectedChannel) {
      this.accountForm.splits.creator = selectedChannel.splits.user ?? 0;
      this.accountForm.splits.collab = selectedChannel.splits.network ?? 0;
      this.accountForm.splits.third_party = selectedChannel.splits.other ?? 0;

      if (this.dashboardUser.payment_method) {
        this.accountForm.payment_info.payment_method =
          this.dashboardUser.payment_method;
      }
      const paypalEmail = this.dashboardUser.payment_details?.paypal?.email;
      if (paypalEmail) {
        this.accountForm.payment_info.paypal_email = paypalEmail.trim();
      }
      const accountNumber =
        this.dashboardUser.payment_details?.wire?.account_number;
      if (accountNumber) {
        this.accountForm.payment_info.bank_account_number = accountNumber;
      }
      const swiftCode = this.dashboardUser.payment_details?.wire?.swift_code;
      if (swiftCode) {
        this.accountForm.payment_info.swift_code = swiftCode;
      }
      const bankAddress =
        this.dashboardUser.payment_details?.wire?.bank_address;
      if (bankAddress) {
        this.accountForm.payment_info.bank_address = bankAddress;
      }
    }
  }

  public async mounted() {
    if (this.createView) {
      window["analytics"]["page"]("/mastersheet/create");
    }
    this["$toast"].add({
      severity: "info",
      summary: "Fill out all needed info",
      group: "bl",
      detail:
        "Go through and fill out the required fields in each tab (if applicable). Remember to assign a creator in order to save the account.",
    });
    if (this.createView) {
      if (this.draftId) {
        const response = await accountDraftsApi.getDraftAccount(this.draftId);
        if (response && response.status === 200) {
          const draft = response.data.data;
          this.accountForm = draft.account;
          this.$refs["managers-edit"]["fetchManagers"](draft.managers);
        } else {
          this.displayMaximizableSync = false;
          this.$router.push("/mastersheet");
        }
      } else {
        this.accountForm = {
          type: "YouTube Channel",
          youtube_channel: {},
          clip_license: {},
          podcast: {},
          china_platform: {},
          payment_info: {},
          splits: {
            creator: 0,
            collab: 0,
            third_party: 0,
          },
          social_media: {},
        };
      }
    } else {
      await dispatchGetAccountFull(this.$store, { id: this.accountId });
      if (this.account.id) {
        this.accountForm = JSON.parse(JSON.stringify(this.account));
        this.accountForm.splits = {
          creator: this.accountForm.splits.creator || 0,
          collab: this.accountForm.splits.collab || 0,
          third_party: this.accountForm.splits.third_party || 0,
        };
        this.youtubeThumbnailUrl =
          this.accountForm.youtube_channel__thumbnail_url;
        delete this.accountForm.youtube_channel__thumbnail_url;
      } else {
        this.$router.push("/matersheet/youtube");
      }
    }
  }

  public beforeDestroy() {
    this["$toast"].removeAllGroups();
  }
}
