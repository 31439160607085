

































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { readIsAlpha } from "@/store/auth/getters";
import {
  dispatchListManagerRoles,
  dispatchListManagers,
  dispatchListMappingGroups,
  dispatchListOffices,
  dispatchListTeams,
} from "@/store/main/actions";
import {
  readManagerRoles,
  readManagers,
  readMappingGroups,
  readOffices,
  readTeams,
} from "@/store/main/getters";
import DataView from "primevue/dataview";
import { api } from "@/api";
import Fuse from "fuse.js";
import { extend } from "vee-validate";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import "@trevoreyre/autocomplete-vue/dist/style.css";

extend("managerSplitTotal", {
  params: ["managers"],
  // @ts-ignore
  validate(value, { managers }) {
    if (managers.length) {
      const total = managers.map((item) => item.split).reduce((a, b) => a + b);
      return total == 1 || total == 0;
    }
    return false;
  },
  message: "Splits must add up to 100%",
});

@Component({
  components: {
    DataView: DataView,
    Autocomplete,
  },
})
export default class ManagersEdit extends Vue {
  @Prop() public readonly accountId: number | undefined;
  @Prop() public readonly createView!: boolean;
  @Prop() public readonly readonly!: boolean;

  public members = [];
  public loaded = false;
  public filteredManagers = [];
  public fuse = null;
  public originalMembersString = "[]";
  public dialog = false;
  public namedMappingGroups = [];

  public managerHistory = [];
  public managerHistoryIsLoading = false;

  private filterMembersOptions = {
    includeScore: true,
    keys: ["first_name", "last_name", "nickname", "email"],
  };

  public get isAlpha() {
    return readIsAlpha(this.$store);
  }

  public get managers() {
    return readManagers(this.$store);
  }

  public get offices() {
    return readOffices(this.$store);
  }

  public get teams() {
    return readTeams(this.$store);
  }

  public get mappingGroups() {
    return readMappingGroups(this.$store);
  }

  public teamName(teamId: number) {
    if (teamId) {
      return this.teams.filter((item) => teamId === item.id)[0].name;
    }
    return null;
  }

  public get managerRoles() {
    return readManagerRoles(this.$store);
  }

  public getRoleName(roleId: number) {
    if (roleId) {
      const role = this.managerRoles.filter((item) => item.id === roleId)[0];
      if (role) {
        return role.name;
      }
      return "";
    }
    return "";
  }

  public searchManagers(event) {
    return this.fuse.search(event).map((item) => item.item);
  }

  public async fetchManagers(managers = null) {
    let members = managers;
    if (!managers) {
      const response = await api.listAccountManagers(this.accountId);
      if (response) {
        members = response.data;
      }
    }

    this.members = members.map((member) => {
      const manager = this.managers.find(
        (manager) => member.manager_id === manager.id
      );
      return {
        ...manager,
        role_id: member.role_id || 4,
        split: member.split,
      };
    });
    this.originalMembersString = JSON.stringify(this.members);
  }

  public async fetchManagerHistory() {
    if (!this.isAlpha) {
      return;
    }

    this.managerHistoryIsLoading = true;
    const response = await api.listAccountPreviousManagers(this.accountId);
    if (response) {
      this.managerHistory = response.data.map((history) => {
        const managers = history.managers.map((account_manager) => {
          const manager = this.managers.find(
            (manager) => account_manager.manager_id === manager.id
          );
          return {
            manager: {
              name: `${manager.first_name} ${manager.last_name}`,
              email: manager.email,
            },
            split: `${account_manager.split * 100}%`,
            role: this.managerRoles.find(
              (role) => role.id === account_manager.role_id
            ).name,
          };
        });
        return {
          id: history.id,
          managers,
          created_at: history.created_at,
        };
      });
    }
    this.managerHistoryIsLoading = false;
  }

  public loadMappingGroupTemplate(value) {
    this.members = value.members.map((member) => {
      const manager = this.managers.filter(
        (manager) => member.manager_id === manager.id
      )[0];
      return {
        ...manager,
        role_id: member.role_id || 4,
        split: member.split,
      };
    });
    this.dialog = false;
  }

  public async saveManagers(accountId: number = null) {
    const create = !!accountId;
    accountId = accountId ? accountId : this.accountId;
    const payload = this.members.map((member) => {
      return {
        account_id: accountId,
        manager_id: member.id,
        role_id: member.role_id || 4,
        split: member.split,
      };
    });
    // @ts-ignore
    if (payload.map((item) => item.split).reduce((a, b) => a + b) != 1) {
      payload.forEach((manager) => {
        manager.split = 1 / payload.length;
      });
    }

    // nothing to update
    if (this.originalMembersString === JSON.stringify(this.members)) {
      return true;
    }

    const response = await api.updateAccountManagers(accountId, payload);
    if (response.status == 200) {
      if (!create) {
        await Promise.all([this.fetchManagers(), this.fetchManagerHistory()]);
      }
      return true;
    }
    return false;
  }

  public selectManager($event) {
    if (!this.members.find((manager) => manager.id === $event.id)) {
      this.members.push({ ...$event, role_id: 4, split: 0 });
    }
    (this.$refs["autocomplete-search"] as HTMLInputElement).value = "";
  }

  public removeMember(managerId: number) {
    this.members = this.members.filter((member) => member.id !== managerId);
  }

  public resetMembers() {
    this.members = JSON.parse(this.originalMembersString);
  }

  public async created() {
    if (!this.createView) {
      window["analytics"]["page"](`/mastersheet/${this.accountId}/managers`);
    }
    if (!this.offices.length) {
      await dispatchListOffices(this.$store);
    }
    if (!this.teams.length) {
      await dispatchListTeams(this.$store);
    }

    await dispatchListManagers(this.$store);
    this.fuse = new Fuse(this.managers, this.filterMembersOptions);

    await dispatchListMappingGroups(this.$store);
    this.namedMappingGroups = this.mappingGroups.map((mappingGroup) => {
      const data = mappingGroup;
      data.members = mappingGroup.template.members.map((member) => {
        const filtered = this.managers.filter(
          (manager) => manager.id == member.manager_id
        );
        const manager = filtered.length ? filtered[0] : {};
        return { ...member, ...manager };
      });
      return data;
    });

    await dispatchListManagerRoles(this.$store);

    if (!this.createView) {
      await this.fetchManagers();
      await this.fetchManagerHistory();
    }
  }

  public beforeDestroy() {
    this.$toast.removeGroup("bl");
  }
}
